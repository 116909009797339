/** 企业、专利、商标表格 */
<template>
  <div style="height: 100%">
    <h2 v-if="title" class="details-main-title mb-10">{{ title }}</h2>
    <el-table style="width: 100%" :max-height="maxHeight" :data="data" border @selection-change="handleSelectionChange">
      <el-table-column v-if="isSelection" type="selection"> </el-table-column>
      <el-table-column v-if="isIndex" type="index" :index="indexMethod" label="序号" width="74px">
      </el-table-column>
      <el-table-column v-for="(item, index) in labelData" :key="index" :prop="item.value" :label="item.label"
        :width="item.width" :align="item.align || 'center'">
        <template slot-scope="scope">
          <slot v-if="item.isSlot" :name="item.value" :scope="scope"></slot>
          <template v-else-if="scope.row[item.value] instanceof Array">
            <p v-for="(i, ind) in scope.row[item.value]" :key="ind">
              {{ item.value2 ? i[item.value2] : i }}
            </p>
          </template>
          <template v-else>
            {{ scope.row[item.value] }}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <Page :page="page" :pageSize="pageSize" v-bind="$attrs" v-on="$listeners" />
  </div>
</template>
<script>
import Page from "@/components/Page.vue";
export default {
  props: {
    title: String,
    maxHeight: {
      default: null,
    },
    isIndex: {
      typeof: Boolean,
      default: false,
    },
    isSelection: {
      typeof: Boolean,
      default: false,
    },
    data: {
      typeof: Object,
      default: function () { return [] },
    },
    labelData: Array,
    // 多选时的关键字
    selectionKeywords: {
      typeof: String,
      default: "id",
    },
    // 自定义序号
    isIndexMethod: {
      typeof: Boolean,
      default: false,
    },
    page: {
      default: 1
    },
    pageSize: {
      default: 15
    },
  },
  components: {
    Page,
  },
  data () {
    return {
      multipleSelection: [],
    };
  },
  methods: {
    indexMethod (index) {
      index = this.isIndexMethod ? index + 1 + ((this.page - 1) * this.pageSize) : (index + 1)
      return index
    },
    handleSelectionChange (val) {
      this.multipleSelection = val.map((item) => item[this.selectionKeywords]);
      this.$emit("getMultipleSelection", this.multipleSelection);
    },
  },
};
</script>