/** 年费监控 */
<template>
  <div class="annualCost">
    <section class="annualCost-operation mb-20">
      <!-- 查询 -->
      <div class="annualCost-filters">
        <el-form :inline="true" :model="searchVal" class="demo-form-inline">
          <el-form-item label="关键字">
            <el-input v-model="searchVal.keywords" placeholder="请根据专利号搜索"></el-input>
          </el-form-item>
          <el-form-item label="到期时间">
            <el-select v-model="searchVal.due_date" placeholder="到期时间">
              <el-option v-for="(item, index) in due_dateOptions" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchBtn">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 按钮组 -->
      <div class="annualCost-btns">
        <el-button type="primary" icon="el-icon-time" @click="dialogSetReminder = true">设置提醒</el-button>
        <el-button type="primary" @click="dialogMonitorAdd = true">添加监控</el-button>
        <el-button type="primary" @click="monitorCancel">取消监控</el-button>
      </div>
    </section>
    <AssetsTable :data="annualCostData.data" :labelData="annualCostData.label" :isSelection="true"
      selectionKeywords="patent_num" @getMultipleSelection="getMultipleSelection" :page="searchVal.page" :pageSize="10"
      :total="annualCostData.pageTotal" @changePage="changePage">
      <template v-slot:due_date="{ scope }">
        <span :class="{
            warning: scope.row.due_date <= 30,
            error: scope.row.due_date <= 0,
          }">
          {{ scope.row.due_date < 0 ? "逾期" : "" }}
          {{ scope.row.patent_paid ? scope.row.due_date : Math.abs(scope.row.due_date)+"天" }}
        </span>
      </template>
      <template v-slot:patent_content="{ scope }">
        <span>{{
          scope.row.patent_paid
            ? scope.row.patent_content
            : `${scope.row.type}专利第${scope.row.patent_content}年年费`
        }}</span>
      </template>
      <template v-slot:id="{ scope }">
        <el-button type="primary" :disabled="scope.row.patent_paid" class="annualCost-btn" @click="
            (dialogCostReduce = true),
              (row = scope.row),
              (costReduce = row.reduction_fee)
          ">费减确认</el-button>
        <el-button type="primary" :disabled="scope.row.patent_paid" class="annualCost-btn mt-10"
          @click="(dialogCostConfirm = true), (row = scope.row)">缴费确认</el-button>
      </template>
    </AssetsTable>

    <!-- 设置提醒框 -->
    <el-dialog title="年费过期提醒设置" :visible.sync="dialogSetReminder" :lock-scroll="false">
      <el-descriptions :column="1">
        <el-descriptions-item label="通知方式">
          <span class="setReminder-way">短信</span>
        </el-descriptions-item>
        <el-descriptions-item label="短信接收号码">
          <el-form :model="setReminder" :rules="$rules.common" ref="form">
            <el-form-item prop="phone">
              <el-input v-model="setReminder.phone" placeholder="请输入接收短信的手机号"></el-input>
            </el-form-item>
          </el-form>
        </el-descriptions-item>
        <el-descriptions-item label="提醒时间节点">
          <el-popover placement="bottom" v-model="regionVisible">
            <el-input slot="reference" :class="{ 'inp-error': isInputError }" v-model="reminderInput" readonly
              suffix-icon="el-icon-arrow-down" placeholder="请选择时间节点"></el-input>
            <el-checkbox-group v-model="checkedTime" @change="handleCheckedTimeChange">
              <el-row>
                <el-col :xs="8" :sm="8" v-for="(city, index) in timeOptions" :key="index">
                  <el-checkbox :label="city.value">{{
                    city.label
                  }}</el-checkbox>
                </el-col>
              </el-row>
            </el-checkbox-group>
            <div class="select-area-btn align-center">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全部
              </el-checkbox>
            </div>
            <el-button type="primary" size="mini" @click="handlefilter()">确 定</el-button>
          </el-popover>
        </el-descriptions-item>
      </el-descriptions>

      <el-button type="primary" @click="setReminderBtn">保存设置</el-button>
    </el-dialog>

    <!-- 添加监控框 -->
    <el-dialog title="添加新专利年费监控" :visible.sync="dialogMonitorAdd" :lock-scroll="false">
      <label for="">专利号：</label>
      <el-input :class="['ptb-20', { 'inp-error': isInputError }]" type="textarea"
        :autosize="{ minRows: 6, maxRows: 10 }" v-model="monitorAddInput" @input="isInputError = false"
        placeholder="请正确输入专利号，例如：2017107711111+201820882222X+2019309933333"></el-input>
      <div class="flex">
        <span>说明：</span>
        <div>
          <p>1. 仅限添加已授权且有效专利的年费监控</p>
          <p class="mt-10">
            2. 多个专利号之间须用 '+' 号连接，最多一次可添加20件专利；
          </p>
        </div>
      </div>
      <el-button type="primary" @click="monitorAddBtn">添 加</el-button>
    </el-dialog>

    <!-- 费减确认框 -->
    <el-dialog title="费减确认" :visible.sync="dialogCostReduce" :lock-scroll="false">
      <el-descriptions :column="1">
        <el-descriptions-item label="专利名称">
          {{ row.name }}
          <span v-if="row.patent_num" class="important-color">({{ row.patent_num }})</span>
        </el-descriptions-item>
        <el-descriptions-item label="专利权人">{{
          row.patent_aspo
        }}</el-descriptions-item>
        <el-descriptions-item label="费减比例">
          <el-select v-model="costReduce" placeholder="请选择">
            <el-option v-for="(item, index) in costDelOptions" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>
      </el-descriptions>
      <el-button type="primary" @click="costReduceBtn">保 存</el-button>
    </el-dialog>

    <!-- 缴费确认框 -->
    <el-dialog title="缴费确认" :visible.sync="dialogCostConfirm" :lock-scroll="false">
      <el-descriptions :column="4" :colon="false">
        <el-descriptions-item label="专利名称：" span="4">
          {{ row.name }}
          <span v-if="row.patent_num" class="important-color">({{ row.patent_num }})</span>
        </el-descriptions-item>
        <el-descriptions-item label="专利权人：" span="4">{{
          row.patent_aspo
        }}</el-descriptions-item>
        <el-descriptions-item label="费减比例：">{{
          getCostReduceProp
        }}</el-descriptions-item>
        <el-descriptions-item label="专利类型："><span class="important-color">{{
            row.type
          }}</span></el-descriptions-item>
        <el-descriptions-item></el-descriptions-item>
        <el-descriptions-item></el-descriptions-item>
        <el-descriptions-item label="缴费年度："><span class="important-color">第 {{ row.patent_content }} 年</span>
        </el-descriptions-item>
        <el-descriptions-item label="年费金额："><span class="important-color">{{ row.should_pay }}元</span>
        </el-descriptions-item>
        <el-descriptions-item label="滞纳金："><span class="important-color">{{ row.overdue_fee }}元</span>
        </el-descriptions-item>
        <el-descriptions-item label="截止日期：">{{
          row.next_pay_cost_date
        }}</el-descriptions-item>
        <el-descriptions-item label="是否已缴费：" label-class-name="important-color" span="4">
          <el-switch v-model="costConfirm"></el-switch>
          <span class="ml-32" style="font-size: 14px">注意：仅现确认当前专利第{{ row.patent_content }}年度年费{{
              row.should_pay
            }}元是否已缴纳并进入下一年度监控！</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-button type="primary" @click="costConfirmBtn">确 认</el-button>
    </el-dialog>
  </div>
</template>
<script>
import AssetsTable from "@/components/Assets/table.vue";
import {
  getPatentAnnualCostList,
  setPatentMonitorAnnualCost,
  delPatentMonitorAnnualCost,
  setAnnualCostReduce,
  setAnnualCostConfirm,
  getAnnualCostFeeremid,
  setAnnualCostFeeremid,
} from "@/api/patent";
export default {
  name: "AnnualCost",
  components: {
    AssetsTable,
  },
  data () {
    return {
      isInputError: false, //是否展示input错误样式
      searchVal: {
        keywords: "",
        due_date: 0,
        page: 1,
        page_num: 10
      },
      oldSearchVal: {},
      due_dateOptions: [
        { label: "全部", value: 0 },
        { label: "近7天", value: 7 },
        { label: "近30天", value: 30 },
        { label: "近90天", value: 90 },
        { label: "近180天", value: 180 },
        { label: "近360天", value: 360 },
      ],
      annualCostData: {
        label: [
          { label: "专利号", value: "patent_num", width: "160px" },
          { label: "专利名称", value: "name", },
          { label: "申请日", value: "patent_ad_date", width: "100px" },
          {
            label: "费用内容",
            value: "patent_content",
            isSlot: true,
            width: "120px"
          },
          {
            label: "倒计时",
            value: "due_date",
            width: "80px",
            isSlot: true,
          },
          { label: "应缴年费（元）", value: "should_pay", width: "82px" },
          { label: "操作", value: "id", isSlot: true, width: "120px" },
        ],
        data: [],
        pageTotal: 0,
      },
      annualCostSelection: "", //已选中年费监控专利
      row: {},
      dialogSetReminder: false, //设置提醒框
      dialogMonitorAdd: false, //添加监控框
      dialogCostReduce: false, // 费减确认框
      dialogCostConfirm: false, // 缴费确认框

      reminderInput: "",//提醒时间节点
      setReminder: {
        phone: this.$store.state.userInfo.phone,//短信接收手机号
      },
      oldReminderPhone: this.$store.state.userInfo.phone,
      regionVisible: false, //时间节点弹框
      checkAll: false, // 是否全选
      checkedTime: [], //已选区域
      oldCheckedTime: [],
      // 时间节点选项
      timeOptions: [
        { label: "提前3个月", value: "-3" },
        { label: "提前1个月", value: "-1" },
        { label: "当天", value: "0" },
        { label: "1个月滞纳期", value: "+1" },
        { label: "2个月滞纳期", value: "+2" },
        { label: "3个月滞纳期", value: "+3" },
        { label: "4个月滞纳期", value: "+4" },
        { label: "5个月滞纳期", value: "+5" },
        { label: "6个月滞纳期", value: "+6" },
      ],
      isIndeterminate: false, //全选框样式
      // 添加监控专利号
      monitorAddInput: "",
      // 费减比例
      costReduce: 0,
      costDelOptions: [
        {
          value: 0,
          label: "无费减",
        },
        {
          value: 70,
          label: "70%费减",
        },
        {
          value: 85,
          label: "85%费减",
        },
      ],
      costConfirm: false, //缴费确认开关
    };
  },
  watch: {
    dialogSetReminder () {
      this.isInputError = false;
      this.checkedTime = this.oldCheckedTime;
      this.setReminderInputFun();
    },
    dialogMonitorAdd () {
      this.isInputError = false;
      this.monitorAddInput = "";
    },
    dialogCostConfirm () {
      this.costConfirm = false
    }
  },
  computed: {
    getCostReduceProp () {
      let item =
        this.costDelOptions.find(
          (item) => item.value == this.row.reduction_fee
        ) || {};
      return item.label;
    },
  },
  mounted () {
    this.oldSearchVal = { ...this.searchVal };
    this.getAnnualCostList();
    this.getAnnualCostFeeremid();
  },
  methods: {
    // 年费监控列表
    async getAnnualCostList (page = 1) {
      this.searchVal.page = page;
      try {
        let { data } = await getPatentAnnualCostList(this.searchVal);
        data.data.forEach((item) => {
          item["patent_paid"] = this.isPatentPaid(item);
        });
        this.annualCostData.data = data.data;
        this.annualCostData.pageTotal = data.total;
      } catch (error) {
        return false;
      }
    },

    // 判断是否有效期内已缴完所有年费
    isPatentPaid (row) {
      let isPaid = false;
      switch (row.type) {
        case "发明":
          if (row.patent_content >= 20) {
            isPaid = true;
          }
          break;
        case "实用新型":
          if (row.patent_content >= 10) {
            isPaid = true;
          }
          break;
        case "外观设计":
          if (row.patent_content >= 15) {
            isPaid = true;
          }
          break;
        default:
          break;
      }

      //  计算倒计时
      //date1结束时间
      let date1 = new Date(row.next_pay_cost_date);
      //date2当前时间
      let date2 = new Date();
      date1 = new Date(
        date1.getFullYear(),
        date1.getMonth(),
        date1.getDate()
      ).getTime();
      date2 = new Date(
        date2.getFullYear(),
        date2.getMonth(),
        date2.getDate() + 1
      ).getTime();
      row.due_date = (date1 - date2) / (24 * 60 * 60 * 1000); //计算当前时间与结束时间之间相差天数

      if (isPaid) {
        row.patent_content = "有效期内已缴完所有年费";
        row.should_pay = "-";
        row.due_date = "-";
      }
      return isPaid;
    },

    // 获取专利年费提醒时间
    async getAnnualCostFeeremid () {
      try {
        let { data } = await getAnnualCostFeeremid();
        if (data) {
          this.checkedTime = data.value;
          this.oldCheckedTime = data.value;
          this.setReminder.phone = data.user_phone
          this.oldReminderPhone = data.user_phone
          this.setReminderInputFun();
        }
      } catch (error) { }
    },

    // 已监控专利多选操作
    getMultipleSelection (val) {
      this.annualCostSelection = val.join("+");
    },

    // 查询
    searchBtn () {
      this.searchVal.page = 1;
      this.getAnnualCostList();
      this.oldSearchVal = { ...this.searchVal };
    },

    // 设置提醒
    async setReminderBtn () {
      if (this.checkedTime.toString() == this.oldCheckedTime.toString() && this.setReminder.phone == this.oldReminderPhone) {
        if (!this.oldCheckedTime.length) {
          return (this.isInputError = true);
        }
        return false;
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            let { status } = await setAnnualCostFeeremid({
              remind_range: this.checkedTime,
              remind_phone: this.setReminder.phone
            });
            if (status == 200) {
              this.$message.success("提醒时间设置成功");
              this.dialogSetReminder = false;
              this.oldCheckedTime = this.checkedTime;
              this.oldReminderPhone = this.setReminder.phone
            } else {
              this.$message.error("提醒时间设置失败");
            }
          } catch (error) { }
        } else {
          return false;
        }
      });

    },
    // 已选中的时间节点填入到input
    setReminderInputFun () {
      this.reminderInput = this.timeOptions
        .filter((item, i) => {
          return this.checkedTime.includes(item.value);
        })
        .map((item) => {
          return item.label;
        })
        .join(",");
      this.checkAll = this.checkedTime.length === this.timeOptions.length;
      this.isIndeterminate =
        this.checkedTime.length > 0 &&
        this.checkedTime.length < this.timeOptions.length;
    },
    // 时间节点-确认
    handlefilter () {
      this.setReminderInputFun();
      this.regionVisible = false;
      if (this.checkedTime.length) {
        this.isInputError = false;
      }
    },
    // 时间节点-全选
    handleCheckAllChange (val) {
      this.checkedTime = val
        ? this.timeOptions.map((item) => {
          return item.value;
        })
        : [];
      this.isIndeterminate = false;
    },
    // 时间节点-单选
    handleCheckedTimeChange (value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.timeOptions.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.timeOptions.length;
    },

    // 添加专利年费监控
    async monitorAddBtn () {
      if (!this.monitorAddInput) {
        return (this.isInputError = true);
      } else if (this.monitorAddInput.split("+").length > 20) {
        return this.$message.warning("最多一次可添加20件专利");
      }
      try {
        let { data, status, msg } = await setPatentMonitorAnnualCost({ keywords: this.monitorAddInput, monitor_status: 1 });
        if (status == 200) {
          this.$message.success("添加年费监控成功");
          this.dialogMonitorAdd = false;
          this.getAnnualCostList();
        } else if (status == 1000) {
          this.monitorAddInput = data.join("+");
          this.$message.error(msg);
        } else {
          this.$message.error("仅可监控国内有效专利");
        }
      } catch (error) { }
    },

    // 取消监控
    async monitorCancel () {
      if (!this.annualCostSelection) {
        return this.$message.warning("请先勾选已监控的专利");
      }
      try {
        let { status } = await delPatentMonitorAnnualCost({ keywords: this.annualCostSelection, monitor_status: 2 });
        if (status == 200) {
          this.$message.success("取消年费监控成功");
          this.annualCostData.data = this.annualCostData.data.filter(
            (item, i) => {
              return !this.annualCostSelection
                .split("+")
                .includes(item.patent_num);
            }
          );
          if (!this.annualCostData.data.length && this.searchVal.page > 1) {
            this.getAnnualCostList(this.searchVal.page - 1);
          }
        } else {
          this.$message.error("取消年费监控失败");
        }
      } catch (error) { }
    },
    // 费减保存
    async costReduceBtn () {
      if (this.costReduce === "") {
        return this.$message.warning("请选择费减比例");
      }
      try {
        let { status, msg } = await setAnnualCostReduce(
          this.row.id,
          this.costReduce
        );
        if (status == 200) {
          this.$message.success("保存成功");
          this.dialogCostReduce = false;
          this.calculateCostReduce()
        } else {
          this.$message.error(msg);
        }
      } catch (error) { }
    },
    // 费减比例计算
    calculateCostReduce (isZero) {
      let my_should_pay =
        (this.row.should_pay / (100 - this.row.reduction_fee)) * 100;
      if (isZero) {
        this.row.should_pay = my_should_pay
        this.row.reduction_fee = 0 // 费减比例归零
        this.calculateShouldPay()
      } else {
        this.row.should_pay =
          my_should_pay - my_should_pay * (this.costReduce / 100);
        this.row.reduction_fee = this.costReduce;
      }
    },
    // 应缴年费计算
    calculateShouldPay () {
      let typeYearMoney = {
        发明: [900, 900, 900, 1200, 1200, 1200, 2000, 2000, 2000, 4000, 4000, 4000, 6000, 6000, 6000, 8000, 8000, 8000, 8000, 8000],
        实用新型: [600, 600, 600, 900, 900, 1200, 1200, 1200, 2000, 2000, 3000, 3000, 3000, 3000, 3000],
        外观设计: [600, 600, 600, 900, 900, 1200, 1200, 1200, 2000, 2000, 3000, 3000, 3000, 3000, 3000],
      }
      this.row.should_pay = typeYearMoney[this.row.type][this.row.patent_content * 1 - 1];
    },
    // 缴费确认
    async costConfirmBtn () {
      if (!this.costConfirm) {
        return this.$message.warning("请打开是否已缴费开关");
      }
      try {
        let { status, msg } = await setAnnualCostConfirm(this.row.id);
        if (status == 200) {
          this.$message.success("已缴费");
          this.dialogCostConfirm = false;
          // 缴费年度+1
          this.row.patent_content = this.row.patent_content * 1 + 1;
          // 截止日期加1年
          let date = new Date(this.row.next_pay_cost_date);
          let year = date.getFullYear() + 1;
          let month = date.getMonth() + 1;
          let day = date.getDate();
          if (month < 10) {
            month = "0" + month;
          }
          if (day < 10) {
            day = "0" + day;
          }
          this.row.next_pay_cost_date = year + "-" + month + "-" + day;

          this.calculateCostReduce(true)

          this.row.patent_paid = this.isPatentPaid(this.row);
        } else {
          this.$message.error("缴费失败");
        }
        this.costConfirm = false;
      } catch (error) { }
    },
    // 分页切换
    changePage (page) {
      this.getAnnualCostList(page);
    },
  },
};
</script>
<style lang="scss" scoped>
.annualCost {
  height: 100%;
}
::v-deep .el-table {
  th.el-table__cell,
  td.el-table__cell {
    font-size: 15px;
  }
  td.el-table__cell {
    .warning {
      color: #e6a23c;
    }
    .error {
      color: #f56c6c;
    }
  }
}
::v-deep .el-dialog {
  width: 850px;
  .el-dialog__body {
    font-size: 16px;
    .el-button {
      display: block;
      margin: 20px auto 0;
    }
  }
}

::v-deep .el-descriptions {
  .el-descriptions-item {
    padding-bottom: 18px;
    .el-descriptions-item__container {
      align-items: center;
      font-size: 16px;
      .el-descriptions-item__label {
        width: 110px;
        text-align: right;
        display: block;
      }
      .el-descriptions-item__content {
        flex: 1;
        .el-form-item {
          margin: 0;
        }
      }
    }
  }
}
.annualCost-btn {
  width: 100%;
  margin-left: 0;
  padding: 12px 17px;
  font-size: 13px;
}
.setReminder-way {
  &::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: #409eff;
    border-radius: 50%;
    margin-right: 10px;
  }
}
::v-deep .important-color {
  color: #f56c6c;
}
</style>
<style lang="scss">
.el-popover {
  max-width: 666px;
  .el-checkbox-group {
    .el-checkbox {
      width: 25%;
      margin-bottom: 10px;
    }
  }
  .el-checkbox {
    margin-right: 0;
    .el-checkbox__label {
      font-size: 16px;
    }
    .el-checkbox__inner {
      width: 16px;
      height: 16px;
    }
  }
  .el-button {
    display: block;
    margin: 10px auto 0;
  }
}
</style>