<template>
  <el-pagination :hide-on-single-page="true" class="page-content mt-50 pa-0" :current-page="page" :page-size="pageSize"
    :total="myTotal" :pager-count="5" background layout="prev, pager, next" prev-text="上一页" next-text="下一页"
    @current-change="changePage">
  </el-pagination>
</template>

<script>
export default {
  name: 'Page',
  props: {
    page: {
      default: 1
    },
    pageSize: {
      default: 15
    },
    total: {
      default: 0
    },
    isMaxTotal: {
      default: false
    }
  },
  data () {
    return {
      myTotal: 0
    }
  },
  watch: {
    total () {
      this.setMaxPage()
    }
  },
  mounted () {
    this.setMaxPage()
  },
  methods: {
    // 设置最大页数
    setMaxPage () {
      this.myTotal = this.total
      if (!this.isMaxTotal) { return false }
      let maxPage = Math.ceil(this.total / this.pageSize)
      if (this.pageSize == 10) {
        if (maxPage > 1000) {
          this.myTotal = 1000 * 10
        }
      } else if (this.pageSize == 15) {
        if (maxPage > 333) {
          this.myTotal = 333 * 15
        }
      }
    },
    // 切换分页
    changePage (page) {
      this.$emit('changePage', page)
    }
  },
}
</script>
<style lang="scss" scoped>
.el-pagination {
  text-align: right;
  &.is-background {
    ::v-deep button {
      padding: 0 16px !important;
    }
    ::v-deep button,
    ::v-deep .el-pager li {
      height: 30px;
      line-height: 30px;
      background-color: #e9ebf2;
      &:not(.disabled).active {
        background-color: #2974ef;
      }
    }
  }
}
</style>